ul {
    display: flex;
    border: 1px solid #a8a8aa;
    padding: 5px;
}

ul li {
    list-style: none;
    border: 1px solid #e3e3e3;
    margin: 3px;
    padding: 3px;
}

ul li button {
    margin-left: 5px;

}

ul input {
    flex: 1;
    padding: 5px;
    border: none;
    outline: none;
    font-size: 1.5rem;
}